import { Col, Row } from "antd";
import "./Footer.scss";

import BrandLogo from "../../../assets/images/shared/brand-logo.svg";
import FacebookIcon from "../../../assets/images/shared/facebook.svg";
import TwitterIcon from "../../../assets/images/shared/twitter.svg";
import LinkedinIcon from "../../../assets/images/shared/linkedin.svg";
import InstagramIcon from "../../../assets/images/shared/insta.svg";
import MaboLogo from "../../../assets/images/shared/mabo-logo.svg";

import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="qt-footer">
      <div className="container">
        <Row gutter={[24, 48]} className="footer-content-wrap">
          <Col xs={24} xl={8}>
            <Link to="/">
              <img className="brand-logo" src={BrandLogo} alt="Brand logo" />
            </Link>
            <p className="text-gray-2 para-s footer-desc">
              We are a startup focused on options trading and stock market
              trading, with a team of experienced professionals with over 30
              years of experience in the nation’s top wealth management firms.
            </p>
          </Col>
          <Col xs={12} xl={{ span: 4 }}>
            <ul className="footer-menu-list">
              <li>
                <Link to="/" className="text-primary para-s">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/platform" className="text-primary para-s">
                  Platform
                </Link>
              </li>
              <li>
                <Link to="/pricing" className="text-primary para-s">
                  Pricing
                </Link>
              </li>
              <li>
                <Link to="/about" className="text-primary para-s">
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="https://portal.quantumtrades.com/"
                  className="text-primary para-s"
                >
                  Login /
                </Link>
                <Link
                  to="https://portal.quantumtrades.com/register"
                  className="text-primary para-s"
                >
                  {" "}
                  Sign up
                </Link>
              </li>
              <li>
                <Link to="/faq" className="text-primary para-s">
                  FAQ
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs={12} xl={{ span: 4 }}>
            <ul className="footer-menu-list">
              <li>
                <Link to="/privacy-policy" className="text-primary para-s">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <a
                  href="https://quantumtrades.com/site/legal-regulatory/"
                  className="text-primary para-s"
                >
                  Legal & Regulatory
                </a>
              </li>
              <li>
                <Link to="/form-crs" className="text-primary para-s">
                  Form CRS
                </Link>
              </li>
              <li>
                <a
                  href="https://quantumtrades.com/site/form-adv/"
                  className="text-primary para-s"
                >
                  Form ADV
                </a>
              </li>
              <li>
                <a
                  href="https://quantumtrades.com/site/documentation-taxes/"
                  className="text-primary para-s"
                >
                  Documentation & Taxes
                </a>
              </li>
              <li>
                <a
                  href="https://quantumtrades.com/site/risk-disclosures/"
                  className="text-primary para-s"
                >
                  Risk Disclosures
                </a>
              </li>
            </ul>
          </Col>
          <Col xl={{ span: 6, offset: 2 }}>
            <ul className="footer-menu-list">
              <li className="para-s text-gray-3">For Support</li>
              <li className="h5 text-primary">
                Email: Support@QuantumTrades.com
              </li>
              <li className="h5 text-primary">Call: +1 916 790 1042</li>
            </ul>
            <div className="social-media-icons">
              <a
                target="_blank"
                href="https://www.facebook.com/QuantumGreeks/"
                rel="noreferrer"
              >
                <img src={FacebookIcon} alt="Facebook Icon" />
              </a>
              <a
                target="_blank"
                href="https://twitter.com/quantum__trades"
                rel="noreferrer"
              >
                <img src={TwitterIcon} alt="twitter Icon" />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/quantum__trades"
                rel="noreferrer"
              >
                <img src={InstagramIcon} alt="instagram Icon" />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/quantumgreeks/"
                rel="noreferrer"
              >
                <img src={LinkedinIcon} alt="linkedin Icon" />
              </a>
            </div>
          </Col>
        </Row>
        <div className="footer-disclaimer">
          <p className="para-s text-gray-3">
            © 2024 Quantum Trades. All rights reserved. 
            <a href="https://quantumtrades.com/site/risk-disclosures/">
              Risk Disclosures 
            </a>
            | 
            <a href="https://quantumtrades.com/site/terms-and-conditions/">
              Terms of Service
            </a>
             | 
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
          <p className="para-s text-gray-3 credits">
            A Site by
            <span>
              <img src={MaboLogo} alt="Mabo designs logo" />
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
