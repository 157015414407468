import React, { useState } from "react";
import { Link, useLocation, NavLink } from "react-router-dom";

import MainHeader from "./MainHeader";
import NavLinks from "./NavLinks";
import SideDrawer from "./SideDrawer";
import Backdrop from "../Backdrop/Backdrop";
import Brandlogo from "../../../assets/images/shared/brand-logo.svg";
// import BrandlogoWhite from "../../../assets/images/shared/brand-logo-white.svg";
// import LanguageIcon from "../../../assets/images/shared/language.svg";
// import LanguageIconWhite from "../../../assets/images/shared/lang-white.svg";
import "./MainNavigation.scss";
// import Button from "../Buttons/Buttons";
import IconSet from "../IconSet/IconSet";

const MainNavigation = () => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const location = useLocation();

  const logoPath1 = "/platform";
  const logoPath2 = "/about";

  const logoSrc =
    location.pathname === logoPath1 || location.pathname === logoPath2
      ? Brandlogo
      : Brandlogo;

  // const langIconSrc =
  //   location.pathname === logoPath1 || location.pathname === logoPath2
  //     ? LanguageIcon
  //     : LanguageIcon;

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
    document.querySelector("body").style.overflow = "hidden";
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
    document.querySelector("body").style.overflow = "scroll";
  };

  return (
    <React.Fragment>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
      {drawerIsOpen && (
        <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
          <nav className="main-navigation__drawer-nav">
            <Link to="/">
              <img className="ham-logo" src={Brandlogo} alt="brand logo" />
            </Link>
            <NavLinks onClose={closeDrawerHandler} />

            <div className="auth-triggers">
              <NavLink to="https://portal.quantumtrades.com/" className="login">
                <p className="para-s">Login</p>
              </NavLink>
              <NavLink
                to="https://portal.quantumtrades.com/register"
                className="signup"
              >
                <p className="text-white para-s">Sign up</p>
              </NavLink>
            </div>
            <IconSet
              bg=""
              iconName="cross-black"
              onClick={closeDrawerHandler}
            />
          </nav>
        </SideDrawer>
      )}

      <MainHeader>
        <div className="container">
          <Link to="/" className="header-brand-logo">
            <img src={logoSrc} alt="Interactive eq logo" />
          </Link>

          <nav className="main-navigation__header-nav">
            <NavLinks />
          </nav>
          <div className="nav-right flex-align-center">
            {/* <img className="from-tab" src={langIconSrc} alt="Language icon" /> */}
            <div className="auth-triggers">
              <NavLink to="https://portal.quantumtrades.com/" className="login">
                <p className="para-s">Login</p>
              </NavLink>
              <NavLink
                to="https://portal.quantumtrades.com/register"
                className="signup"
              >
                <p className="text-white para-s">Sign up</p>
              </NavLink>
            </div>
            <button
              className="main-navigation__menu-btn"
              onClick={openDrawerHandler}
            >
              <span />
              <span />
              <span />
            </button>
          </div>
        </div>
      </MainHeader>
    </React.Fragment>
  );
};

export default MainNavigation;
